/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Textfield from '@atlaskit/textfield';
import { ErrorMessage } from '@atlaskit/form';
import LoadingButton from '@atlaskit/button/loading-button';
import AuthService from '../../services/AuthService';
import { BACK_END_URL } from '../UrlMap';
import { addToastForAPIResponse } from '../toast/ToastHandler';
import { removedAuthentication } from '../../redux/reduxers/auth.slice';

type Inputs = {
  email: string;
  password: string;
};

const initialState = {
  logging: false,
};

const LoginFormWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    setState({
      ...state,
      logging: true,
    });
    AuthService.login(data.email, data.password)
      .then(() => {
        // dispatch(userAuthenticated({ user: res.user }));
        navigate(BACK_END_URL);
      })
      .catch(() => {
        addToastForAPIResponse('error', 'Incorrect Credential');
        dispatch(removedAuthentication());
        setState({
          ...state,
          logging: false,
        });
      });
  };

  const getField = (
    label: string,
    name: 'email' | 'password',
    placeholder: string,
    errorMsg: string,
    type = 'text',
  ) => {
    return (
      <fieldset>
        {label}
        <Textfield
          testId={name}
          type={type}
          id={name}
          placeholder={placeholder}
          {...register(name, { required: true })}
        />
        {name in errors ? <ErrorMessage testId={`error-${name}`}>{errorMsg}</ErrorMessage> : null}
      </fieldset>
    );
  };

  return (
    <LoginFormWrapper className={'login-form-wrapper'}>
      <form onSubmit={handleSubmit(onSubmit)} data-testid={'login-form'}>
        {getField('Email', 'email', 'Your email', 'Please provide your email.')}
        {getField('Password', 'password', 'Password', 'Password is required.', 'password')}
        <fieldset>
          <LoadingButton
            testId={'login-btn'}
            appearance={'primary'}
            type={'submit'}
            isLoading={state.logging === true}
            shouldFitContainer
          >
            Login
          </LoadingButton>
        </fieldset>
      </form>
    </LoginFormWrapper>
  );
};

export default LoginForm;
