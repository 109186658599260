import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModuleCode } from '../../types/iModuleCode';

export type CompanyModuleState = {
  modules: ModuleCode[];
};
/**
 * Initial State
 */
const initialState: CompanyModuleState = {
  modules: [],
};

/**
 * Actions
 */
const actions = {
  setModules: (state: CompanyModuleState = initialState, action: PayloadAction<CompanyModuleState>) => {
    return {
      ...state,
      modules: action.payload.modules,
    };
  },
  removeModules: (state: CompanyModuleState = initialState) => ({
    ...state,
    modules: [],
  }),
};
/**
 * Slice
 */
const CompanyModuleSlice = createSlice({
  name: 'CompanyModules',
  initialState,
  reducers: actions,
});
/**
 * action
 */
export const { setModules, removeModules } = CompanyModuleSlice.actions;
/**
 * reducer
 */
export default CompanyModuleSlice.reducer;
