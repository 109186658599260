import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonItem, Section } from '@atlaskit/menu';
import { JOB_URL } from '../UrlMap';

const ORDER = 'Orders';

const OrderMenu = () => {
  const navigate = useNavigate();
  return (
    <Section>
      <ButtonItem onClick={() => navigate(JOB_URL)}>{ORDER}</ButtonItem>
    </Section>
  );
};

export default OrderMenu;
