import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import BackendPage from '../../layout/BackendPage';
import WelcomeMsg from '../../shared/user/WelcomeMsg';
import { RootState } from '../../redux/makeReduxStore';
import InlineEdit from '../../shared/form/InlineEdit';
import PasswordReset from '../../shared/user/PasswordReset';
import UserAvatarUpload from '../../shared/user/UserAvatarUpload';
import UserProfileService from '../../services/UserProfileService';
import { userAuthenticated } from '../../redux/reduxers/auth.slice';
import { addToastForAPIResponse, apiErrorToast } from '../../shared/toast/ToastHandler';

const UserProfilePage = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const dispatch = useDispatch();

  const onConfirm = async (name: string, newValue: string) => {
    if (!user) return;
    try {
      const res = await UserProfileService.updateUserProfile(name, newValue);
      dispatch(userAuthenticated({ user: res }));
      addToastForAPIResponse('success');
    } catch (error) {
      apiErrorToast(error);
    }
  };
  return (
    <BackendPage pageHeader={<WelcomeMsg />}>
      <Page>
        <Grid spacing="cosy">
          <GridColumn medium={4}>
            <UserAvatarUpload name={'avatarUrl'} defaultValue={user?.avatarUrl || undefined} onConfirm={onConfirm} />
          </GridColumn>
          <GridColumn medium={8}>
            <Grid layout={'fluid'}>
              <GridColumn medium={4}>
                <InlineEdit
                  name={'name'}
                  label={'Name'}
                  testId={'name'}
                  defaultValue={user?.name}
                  onConfirm={onConfirm}
                />
              </GridColumn>
              <GridColumn medium={4}>
                <InlineEdit
                  name={'email'}
                  label={'Email'}
                  testId={'email'}
                  defaultValue={user?.email}
                  onConfirm={onConfirm}
                />
              </GridColumn>
            </Grid>
            <hr />
            <PasswordReset name={'password'} onConfirm={onConfirm} />
          </GridColumn>
        </Grid>
      </Page>
    </BackendPage>
  );
};

export default UserProfilePage;
