import { ModuleCode } from '../types/iModuleCode';
import AppService from './AppService';

const checkIsModuleEnabled = ({
  checkedModule,
  companyModules,
}: {
  checkedModule: ModuleCode;
  companyModules: Array<ModuleCode>;
}) => {
  return companyModules.includes(checkedModule);
};

const domainExchangeCompanyInfo = (hostname: string) => {
  if (`${process.env.REACT_APP_COMPANY_ID || ''}`.trim() !== '') {
    return {
      companyId: process.env.REACT_APP_COMPANY_ID || '',
      companyLogoUrl: process.env.REACT_APP_COMPANY_LOGO || '',
    };
  }
  return AppService.get('/validateDomain', { hostname }).then(({ data }) => {
    // LocalStorageService.setCompanyId(data.companyId);
    return {
      companyId: data.companyId,
      companyLogoUrl: data.companyLogoUrl,
    };
  });
};

export default {
  checkIsModuleEnabled,
  domainExchangeCompanyInfo,
};
