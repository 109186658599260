import React, { useEffect, useState } from 'react';
import Textfield from '@atlaskit/textfield';
import InlineEdit from '@atlaskit/inline-edit';
import styled from 'styled-components';
import { fontSize, gridSize } from '@atlaskit/theme/constants';

const ReadViewContainer = styled.div`
  display: flex;
  font-size: ${fontSize()}px;
  line-height: ${(gridSize() * 2.5) / fontSize()};
  max-width: 100%;
  min-height: ${(gridSize() * 2.5) / fontSize()}em;
  padding: ${gridSize()}px ${gridSize() - 2}px;
  word-break: break-word;
`;
const initialState = {
  value: '',
};
const InlineEditWrapper = ({
  name,
  defaultValue,
  label,
  onConfirm,
  testId = 'inline-edit',
}: {
  defaultValue?: string;
  label: string;
  name: string;
  onConfirm?: (label: string, newValue: string) => void;
  testId?: string;
}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(s => ({
      ...s,
      value: defaultValue || initialState.value,
    }));
  }, [defaultValue]);

  // eslint-disable-next-line
  const changeValue = (value: any) => {
    if (!value) return;
    setState({
      ...state,
      value,
    });
    if (typeof onConfirm === 'function') {
      onConfirm(name, value);
    }
  };

  return (
    <InlineEdit
      label={label}
      onConfirm={changeValue}
      editView={({ value, onChange }) => <Textfield value={value} onChange={onChange} autoFocus testId={testId} />}
      defaultValue={defaultValue}
      readView={() => (
        <ReadViewContainer>{state.value === '' ? 'Click to enter value' : state.value}</ReadViewContainer>
      )}
    />
  );
};

export default InlineEditWrapper;
