import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import Button from '@atlaskit/button/standard-button';
import { COMPANY_EMAIL, COMPANY_NAME } from './shared/Order.constants';

const EmptyView = ({ companyName }: { companyName?: string }) => {
  const getDescription = () => (
    <>
      <span>It looks like there are no active orders under your company</span>
      <br />
      <br />
      <span>{companyName}</span>
    </>
  );
  return (
    <EmptyState
      header={"Opps, didn't find any active orders"}
      description={getDescription()}
      primaryAction={
        <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL || COMPANY_EMAIL}`}>
          <Button appearance="primary">Contact {process.env.REACT_APP_COMPANY_NAME || COMPANY_NAME}</Button>
        </a>
      }
      imageUrl={'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'}
    />
  );
};

export default EmptyView;
