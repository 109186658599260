import axios, { AxiosRequestConfig } from 'axios';
import LocalStorageService from './LocalStorageService';

type iConfigParams = {
  [key: string]: string;
};
type iParams = {
  [key: string]: string | boolean | number | null | undefined | string[];
};

const getEndPointUrl = (url: string) => {
  return `${process.env.REACT_APP_API_END_POINT}${url}`;
};

const getHeaders = (): AxiosRequestConfig | undefined => {
  const token = LocalStorageService.getToken();
  const companyId = LocalStorageService.getCompanyId() || process.env.REACT_APP_COMPANY_ID;
  if (token && companyId) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        comid: companyId,
      },
    };
  }
  if (!token && companyId) {
    return {
      headers: {
        comid: companyId,
      },
    };
  }
  return undefined;
};

const getUrlParams = (params: iConfigParams = {}) => {
  const paramString =
    typeof params === 'object' && Object.keys(params).length > 0 ? new URLSearchParams(params).toString() : '';
  return paramString === '' ? '' : `?${paramString}`;
};

const get = (url: string, params: iConfigParams = {}) => {
  return axios.get(`${getEndPointUrl(url)}${getUrlParams(params)}`, getHeaders());
};

const post = (url: string, params: iParams) => {
  return axios.post(getEndPointUrl(url), params, getHeaders());
};

const put = (url: string, params: iParams) => {
  return axios.put(getEndPointUrl(url), params, getHeaders());
};

const remove = (url: string, params: iConfigParams = {}) => {
  return axios.delete(`${getEndPointUrl(url)}${getUrlParams(params)}`, getHeaders());
};

const getUploadHeaders = (): AxiosRequestConfig | undefined => {
  const header = getHeaders();
  if (header === undefined) {
    return undefined;
  }
  return {
    headers: {
      ...header.headers,
      'Content-Type': 'multipart/form-data',
    },
  };
};

const uploadImage = (url: string, params: FormData) => {
  return axios.post(getEndPointUrl(url), params, getUploadHeaders());
};

export default {
  get,
  post,
  put,
  delete: remove,
  uploadImage,
};
