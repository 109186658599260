import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { Provider } from 'react-redux';
import '@atlaskit/css-reset';
import store from './redux/makeReduxStore';
import 'react-notifications-component/dist/theme.css';
import LoginPage from './pages/auth/LoginPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import { AUTH_URL_LOGIN, BACK_END_URL, JOB_URL, USER_PROFILE_URL } from './shared/UrlMap';
import ProtectedRoute from './shared/ProtectedRoute';
import UserProfilePage from './pages/user/UserProfilePage';
import OrderList from './pages/order/OrderList';

const App = () => {
  return (
    <>
      <ReactNotifications />
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to={BACK_END_URL} />} />
            <Route path={AUTH_URL_LOGIN} element={<LoginPage />} />
            <Route path={BACK_END_URL} element={<ProtectedRoute element={<DashboardPage />} />} />
            <Route path={JOB_URL} element={<ProtectedRoute element={<OrderList />} />} />
            <Route path={USER_PROFILE_URL} element={<ProtectedRoute element={<UserProfilePage />} />} />
          </Routes>
        </Router>
      </Provider>
    </>
  );
};
export default App;
