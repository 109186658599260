import React from 'react';
import styled from 'styled-components';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import Tooltip from '@atlaskit/tooltip';
import { FlexContainer } from '../../styles/styles';

const PaginationDetailContainer = styled.div`
  display: inline-block;

  & span {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
  }
`;

const RefreshBtnContainer = styled.div`
  display: inline-block;
  color: rgb(112, 112, 112);
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
`;

const PaginationDetail = ({
  from,
  to,
  total,
  onRefreshResults,
}: {
  from: number;
  to: number;
  total: number;
  onRefreshResults: () => void;
}) => {
  return (
    <FlexContainer>
      <PaginationDetailContainer data-testid={'results-stats-wrapper'}>
        <span className={'results-count-from'} data-testid={'results-count-from'}>
          {from}
        </span>
        -
        <span className={'results-count-to'} data-testid={'results-count-to'}>
          {to > total ? total : to}
        </span>
        {' of '}
        <span className={'results-count-total'} data-testid={'results-count-total'}>
          {total}
        </span>
      </PaginationDetailContainer>
      <RefreshBtnContainer onClick={onRefreshResults} data-testid={'refresh-btn-wrapper'}>
        <Tooltip content="Refresh results" position={'right'}>
          <RefreshIcon label="refresh" size={'small'} />
        </Tooltip>
      </RefreshBtnContainer>
    </FlexContainer>
  );
};

export default PaginationDetail;
