import React from 'react';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';

const PageInitiatorWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  .content {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const PageInit = () => {
  return (
    <PageInitiatorWrapper>
      <div className={'content'}>
        <h1>Loading...</h1>
        <Spinner />
      </div>
    </PageInitiatorWrapper>
  );
};

export default PageInit;
