import styled from 'styled-components';

const CenteredOverlay = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
`;

export default CenteredOverlay;
