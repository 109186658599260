/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import ExcelSpreadsheet16Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/16';
import { SuccessProgressBar } from '@atlaskit/progress-bar';
import { CSVLink } from 'react-csv';

import { ProgressBarContainer } from '../styles/styles';
import CustomizeModal from '../modal/CustomizeModal';

const ExportBtnContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  &.margin-above {
    margin-top: 32px;
  }

  &.margin-below {
    margin-bottom: 30px;
  }

  &.margin-left {
    margin-left: -30px;
  }
`;
export type iExportReportProps = {
  className?: string;
  total?: number;
  perPage?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFn?: (currentPage: number) => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormatReport: (arr: any[]) => (string | number | true)[][];
  reportFileName: string;
};

const ExportReport = ({
  className,
  total = 1,
  perPage = 10,
  getFn,
  onFormatReport,
  reportFileName,
}: iExportReportProps) => {
  const [isProgressBarModalOpen, setIsProgressBarModalOpen] = useState(false);
  const [exportProgress, setExportProgress] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [report, setReport] = useState<any[]>([]);

  const onGenerateReport = async () => {
    const totalPageSize = Math.ceil(total / perPage);
    const reportData = [];
    setIsProgressBarModalOpen(true);
    for (let i = 1; i <= totalPageSize; i++) {
      if (typeof getFn !== 'function') return;
      const { data } = await getFn(i);
      // : await getJobs(i, perPage, config || {});
      reportData.push(...data);
      setExportProgress(i / totalPageSize);
    }

    const finalReport = onFormatReport(reportData);
    setReport(finalReport);
  };

  const getCSVLink = () => (
    <CSVLink data={report} filename={reportFileName}>
      <Button
        appearance={'primary'}
        isDisabled={exportProgress < 1}
        onClick={() => setIsProgressBarModalOpen(false)}
        style={{ textDecoration: 'none' }}
      >
        Download Report
      </Button>
    </CSVLink>
  );

  const getProgressBar = () => (
    <ProgressBarContainer>
      <SuccessProgressBar value={exportProgress} />
    </ProgressBarContainer>
  );

  const onCancelProgressBar = () => {
    setIsProgressBarModalOpen(false);
    setExportProgress(0);
  };

  return (
    <>
      <ExportBtnContainer onClick={onGenerateReport} className={className}>
        <ExcelSpreadsheet16Icon label={'Export Report'} testId={'exportReportBtn'} />
      </ExportBtnContainer>
      <CustomizeModal
        isOpen={isProgressBarModalOpen}
        customizedBtn={getCSVLink()}
        onCancel={onCancelProgressBar}
        modalBody={getProgressBar()}
        testIdPrefix={'export-popup'}
        modalHeading={exportProgress === 1 ? 'Completed' : 'Exporting...'}
      />
    </>
  );
};

export default ExportReport;
