import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iContact from '../../types/contact/iContact';

export type AuthState = {
  isAuthenticated?: boolean;
  user?: iContact;
};
/**
 * Initial State
 */
const initialState: AuthState = {
  isAuthenticated: false,
};

/**
 * Actions
 */
const actions = {
  userAuthenticated: (state: AuthState = initialState, action: PayloadAction<AuthState>) => ({
    ...state,
    isAuthenticated: true,
    user: action.payload.user,
  }),
  removedAuthentication: (state: AuthState = initialState) => ({
    ...state,
    isAuthenticated: initialState.isAuthenticated,
    user: undefined,
  }),
};
/**
 * Slice
 */
const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: actions,
});
/**
 * action
 */
export const { userAuthenticated, removedAuthentication } = AuthSlice.actions;
/**
 * reducer
 */
export default AuthSlice.reducer;
