import { useSelector } from 'react-redux';
import ModulingService from '../../services/ModulingService';
import { RootState } from '../../redux/makeReduxStore';
import { ModuleCode } from '../../types/iModuleCode';
/**
 *
 * checkedModule the module want to check like service provider/ao
 * children: the return when check is successful
 * exception: the return when check is un-successful; can be customized for route exception
 */
const ModuleEnabledWrapper = ({
  checkedModule,
  children,
  exception,
}: {
  checkedModule?: ModuleCode;
  children: JSX.Element | null;
  exception?: JSX.Element;
}) => {
  const { modules } = useSelector((s: RootState) => s.companyModules);

  if (checkedModule && !ModulingService.checkIsModuleEnabled({ checkedModule, companyModules: modules || [] })) {
    return exception || null;
  }
  return children;
};

export default ModuleEnabledWrapper;
