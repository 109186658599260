import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import DocumentIcon from '@atlaskit/icon/glyph/document';
import Button from '@atlaskit/button';
import { apiErrorToast } from '../toast/ToastHandler';
import { handleNullException } from '../../services/UtilsService';
import LinkBtnUrl from '../buttons/LinkBtnUrl';
import MathHelper from '../../services/MathHelper';

type iState<T> = {
  isLoading: boolean;
  data: Array<T>;
};

export type iFetchRelatedElementsProps = {
  //  eslint-disable-next-line
  fetchFn: () => Promise<any>;
  renderType: string;
  dataSource: Array<string>;
  urlPrefix?: string;
  isPaginated?: boolean;
};

const FetchRelatedElements = <T extends { id: string }>({
  fetchFn,
  renderType,
  dataSource,
  urlPrefix,
  isPaginated = true,
}: iFetchRelatedElementsProps) => {
  const initialState: iState<T> = {
    isLoading: true,
    data: [],
  };
  const [state, setState] = useState(initialState);

  useEffect(
    () => {
      const fetchData = async () => {
        setState(prevState => ({ ...prevState, isLoading: true }));
        try {
          const res = await fetchFn();
          const data = isPaginated ? res.data : res;
          setState(prevState => ({ ...prevState, isLoading: false, data }));
        } catch (error) {
          apiErrorToast(error);
          setState(prevState => ({ ...prevState, isLoading: false }));
        }
      };
      fetchData();
    },
    //  eslint-disable-next-line
    [],
  );

  const getElement = (item: T) => {
    switch (renderType) {
      case 'text':
        return handleNullException(item, dataSource[0]);
      case 'number':
        return Number(handleNullException(item, dataSource[0]));
      case 'number-conversion':
        return MathHelper.mul(
          Number(handleNullException(item, dataSource[0])),
          Number(handleNullException(item, dataSource[1])) || 1,
        );
      case 'link':
        return (
          <LinkBtnUrl
            key={handleNullException(item, dataSource[0])}
            btnName={handleNullException(item, dataSource[1])}
            url={`${urlPrefix}/${handleNullException(item, dataSource[0])}`}
          />
        );
      case 'attachment-popup':
        return (
          <Button
            key={handleNullException(item, dataSource[0])}
            iconBefore={<DocumentIcon label={'document'} size={'small'} />}
            onClick={() => window.open(handleNullException(item, dataSource[2]))}
          >
            {handleNullException(item, dataSource[1])}
          </Button>
        );
      default:
        return null;
    }
  };

  const getRenderComponents = () => {
    const parts = state.data?.map((item: T, index: number) => {
      const element = getElement(item);
      if (element === null) {
        return null;
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index} className={'element'}>
          {getElement(item)}
        </span>
      );
    });
    return parts;
  };
  if (state.isLoading) return <Spinner size={'small'} testId={'loading'} />;
  return <div data-testid={'related-element-wrapper'}>{getRenderComponents()}</div>;
};

export default FetchRelatedElements;
