import React, { useState } from 'react';
import styled from 'styled-components';
import { gridSize } from '@atlaskit/theme/constants';
import { Grid, GridColumn } from '@atlaskit/page';
import { LoadingButton } from '@atlaskit/button';
import Textfield from '../form/Textfield';
import UserProfileService from '../../services/UserProfileService';

const PasswordResetWrapper = styled.div`
  .content {
    margin-top: ${gridSize()}px;
  }
`;
type iState = {
  newPassword: string;
  confirmNewPassword?: string;
  isDiff?: boolean;
  isDuplicate?: boolean;
  isInvalid?: boolean;
  saving: boolean;
};
const initialState: iState = {
  newPassword: '',
  saving: false,
};
const PasswordReset = ({
  name,
  onConfirm,
}: {
  name: string;
  onConfirm?: (name: string, newValue: string) => Promise<void>;
}) => {
  const [state, setState] = useState(initialState);

  const handleNewPasswordChanged = (newValue: string) => {
    setState({
      ...state,
      newPassword: newValue,
      confirmNewPassword: newValue === '' ? undefined : '',
      isDiff: undefined,
      isInvalid: false,
    });
  };

  const handleConfirmNewPasswordChanged = (newValue: string) => {
    setState({
      ...state,
      confirmNewPassword: newValue,
      isDiff: newValue !== '' && newValue !== state.newPassword,
    });
  };
  const changeValue = () => {
    if (typeof onConfirm === 'function' && state.confirmNewPassword) {
      setState({
        ...state,
        saving: true,
      });
      onConfirm(name, state.confirmNewPassword);
      setState({
        ...state,
        newPassword: '',
        confirmNewPassword: undefined,
        isDiff: undefined,
      });
    }
  };

  const onPrecheck = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.target.value.trim()) return;
    const { isValid } = await UserProfileService.precheckPasswordComplexity(event.target.value.trim());

    if (!isValid) {
      setState({
        ...state,
        isInvalid: true,
      });
    }
  };

  const getConfirmNewPasswordInput = () => {
    if (state.confirmNewPassword === undefined) return null;
    return (
      <>
        <GridColumn medium={3}>
          <Textfield
            defaultValue={state.confirmNewPassword}
            label={'Confirm new password'}
            placeholder={'confirm new password'}
            name={'confirm-new-password'}
            type={'password'}
            onChange={handleConfirmNewPasswordChanged}
            helper={state.isDiff === true ? 'Confirm password is different.' : ''}
            appStyle={state.isDiff ? 'danger' : undefined}
          />
        </GridColumn>
        <GridColumn medium={2}>
          <LoadingButton isLoading={state.saving} isDisabled={state.isDiff !== false} onClick={changeValue}>
            Reset
          </LoadingButton>
        </GridColumn>
      </>
    );
  };

  return (
    <PasswordResetWrapper>
      <h6>Password Reset</h6>
      <div className={'content'}>
        <Grid>
          <GridColumn medium={3}>
            <Textfield
              label={'New password'}
              placeholder={'new password'}
              name={'new-password'}
              type={'password'}
              onChange={handleNewPasswordChanged}
              onPrecheck={onPrecheck}
              helper={state.isInvalid === true ? 'Invalid password' : ''}
              appStyle={state.isInvalid ? 'danger' : undefined}
            />
          </GridColumn>
          {getConfirmNewPasswordInput()}
        </Grid>
      </div>
    </PasswordResetWrapper>
  );
};

export default PasswordReset;
