import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';
import AppPage from '../../layout/AppPage';
import CenteredDiv from '../../shared/CenteredDiv';
import LoginForm from '../../shared/auth/LoginForm';
import CompanyLogo from '../../shared/company/CompanyLogo';
import ModulingService from '../../services/ModulingService';
import CompanyDomainUnknown from '../../shared/company/CompanyDomainUnknown';
import { apiErrorToast } from '../../shared/toast/ToastHandler';

const LoginPageWrapper = styled.div`
  height: 100%;
  .auth-div {
    height: 100%;
    .centered-div {
      width: calc(100% - 1rem);
      max-width: 400px;
    }
  }
`;
type iState = {
  companyId?: string;
  companyLogoUrl?: string;
  isLoading: boolean;
};
const initialState: iState = {
  isLoading: true,
};
const LoginPage = () => {
  const [state, setState] = useState(initialState);
  const getContent = () => {
    if (state.isLoading) {
      return <Spinner />;
    }
    if (typeof state.companyId === 'undefined') {
      return <CompanyDomainUnknown />;
    }
    return (
      <>
        <CompanyLogo src={state.companyLogoUrl} />
        <LoginForm />
      </>
    );
  };
  useEffect(() => {
    let isCancelled = false;
    const exchangeDomainWithCompanyInfo = async () => {
      try {
        const result = await ModulingService.domainExchangeCompanyInfo(window.location.hostname);
        if (isCancelled) return;
        setState(prevState => ({
          ...prevState,
          ...result,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        apiErrorToast(error);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
      }
    };
    exchangeDomainWithCompanyInfo();
    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <AppPage>
      <LoginPageWrapper>
        <CenteredDiv className={'auth-div'} data-testid={'auth-div'}>
          {getContent()}
        </CenteredDiv>
      </LoginPageWrapper>
    </AppPage>
  );
};

export default LoginPage;
