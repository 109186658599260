import React from 'react';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { iBtnAppearance } from '../UITypes/ui.types';

const defaultModalHeading = <span>Are you sure to start?</span>;
const defaultModalBody = <p>Please confirm you are about to start it.</p>;
export type iCustomizeModalProps = {
  isOpen?: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  isConfirming?: boolean;
  isDisabled?: boolean;
  confirmBtnName?: string;
  confirmBtnAppearance?: iBtnAppearance;
  modalHeading?: React.ReactNode;
  modalBody?: React.ReactNode;
  bottom?: React.ReactNode;
  testIdPrefix?: string;
  width?: 'small' | 'medium' | 'large' | 'x-large' | number | string;
  height?: number | string;
  customizedBtn?: React.ReactNode;
  cancelBtnName?: string;
};

const CustomizeModal = ({
  isOpen,
  onConfirm,
  onCancel,
  isConfirming = false,
  confirmBtnName = 'Confirm',
  confirmBtnAppearance = 'primary',
  modalHeading = defaultModalHeading,
  isDisabled = false,
  modalBody = defaultModalBody,
  bottom = null,
  testIdPrefix,
  width,
  height,
  customizedBtn,
  cancelBtnName = 'Cancel',
}: iCustomizeModalProps) => {
  const getCancelBt = () => {
    if (cancelBtnName.trim() === '') {
      return null;
    }
    return (
      <Button onClick={onCancel} testId={testIdPrefix ? `${testIdPrefix}-cancel-button` : 'cancel-button'}>
        {cancelBtnName}
      </Button>
    );
  };

  const getConfirmBtn = () => {
    if (customizedBtn) {
      return customizedBtn;
    }
    return (
      <LoadingButton
        onClick={onConfirm}
        appearance={confirmBtnAppearance}
        isDisabled={isDisabled}
        testId={testIdPrefix ? `${testIdPrefix}-confirm-button` : 'confirm-button'}
        isLoading={isConfirming}
      >
        {confirmBtnName}
      </LoadingButton>
    );
  };

  const getModal = () => (
    <Modal
      testId={testIdPrefix ? `${testIdPrefix}-customize-modal` : 'customize-modal'}
      onClose={onCancel}
      width={width || 'medium'}
      height={height || 'auto'}
    >
      <ModalHeader>
        {typeof modalHeading === 'object' ? modalHeading : <ModalTitle>{modalHeading}</ModalTitle>}
      </ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
      <ModalFooter>
        <div>{bottom}</div>
        <ButtonGroup>
          {getCancelBt()}
          {getConfirmBtn()}
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
  return isOpen ? <ModalTransition>{getModal()}</ModalTransition> : null;
};

export default CustomizeModal;
