import React from 'react';

import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400 } from '@atlaskit/theme/colors';

import Flag from '@atlaskit/flag';

const ErrorFlag = ({ title }: { title: string }) => (
  <Flag
    appearance="error"
    icon={<ErrorIcon label="error" secondaryColor={R400} />}
    id="error"
    key="error"
    title={title}
  />
);

export default ErrorFlag;
