import React from 'react';
import { FlexSpaceBetweenContainer } from '../../../shared/styles/styles';
import { iLabelValuePair } from '../../../shared/UITypes/ui.types';
import TypeFilter from './FilterByType';

export type iJobFilterProps = {
  options: Array<iLabelValuePair>;
  onSelectJobType: (type: string) => void;
  selectedTypes: Array<string>;
  children: React.ReactNode;
};

const JobFilter = ({ options, onSelectJobType, selectedTypes, children }: iJobFilterProps) => {
  return (
    <FlexSpaceBetweenContainer data-testid={'job-filter'}>
      <TypeFilter options={options} onSelectJobType={onSelectJobType} selectedTypes={selectedTypes} />
      <div style={{ maxWidth: '400px' }}>{children}</div>
    </FlexSpaceBetweenContainer>
  );
};

export default JobFilter;
