import React from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';

const CompanyExceptionEmptyState = ({
  header,
  description,
  primaryAction,
}: {
  header: string;
  description: string;
  primaryAction: React.ReactNode;
}) => {
  const getSecondaryAction = () => {
    const supportEmailAddress = `${process.env.REACT_APP_SUPPORT_EMAIL || ''}`.trim();
    if (supportEmailAddress.trim() === '') {
      return null;
    }
    return (
      <Button appearance={'primary'} testId={'contact-support-btn'}>
        <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL || ''}`} style={{ color: '#42526E' }}>
          Contact
        </a>
      </Button>
    );
  };

  const props = {
    secondaryAction: getSecondaryAction(),
    imageUrl: '',
    header,
    description,
    primaryAction,
  };
  // eslint-disable-next-line
  return <EmptyState {...props} />;
};

export default CompanyExceptionEmptyState;
