import styled from 'styled-components';

export const ShowHideButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const ShowHideModalBodyWrapper = styled.div` 
   display:flex;
  .show-hide-columns-button {
    display: flex;
    justify-content: flex-end;
  }
  .show-hide-group {
    display: block;
    margin-right: 1rem;
    .show-hide-group-label {
        border-bottom: 1px solid black;
        margin-bottom: 0.5rem;
        font-weight:500;
    }
    .show-hide-group-rows{
        display:flex;
    }
  }
  .
`;
