import AppService from './AppService';

const uploadImage = (params: FormData) => {
  return AppService.uploadImage('/asset', params).then(res => {
    return res;
  });
};
export default {
  uploadImage,
};
