import React, { useState } from 'react';
import _ from 'lodash';
import DynamicTable from '@atlaskit/dynamic-table';
import StatusLozenge from '../../shared/status/StatusLozenge';
import iJob from '../../types/job/iJob';
import iDispatchNote from '../../types/dispatchNote/iDispatchNote';
import { ATTACHMENT_ENTITY_NAME, ORDER_PAGE_NAME } from './shared/Order.constants';
import { iConfigColumn, iSortOrderType } from '../../shared/UITypes/ui.types';
import { formatDate, getHeads, handleNullException, handleNumber } from '../../services/UtilsService';
import FetchRelatedElements from '../../shared/RelatedElements/FetchRelatedElements';
import JobService from '../../services/JobService';

const getRows = ({ data, columns }: { data: Array<iJob>; columns: Array<iConfigColumn> }) => {
  return data.map((item: iJob) => {
    const latestDispatchNote: iDispatchNote | undefined =
      item.dispatchNoteItems.length > 0
        ? _.sortBy(item.dispatchNoteItems, 'updatedAt').reverse()[0].dispatchNote
        : undefined;

    const cells = columns.map((column: iConfigColumn) => {
      const { key, type, dataSource } = column;
      switch (type) {
        case 'text':
          return {
            key: handleNullException(item, key),
            content: handleNullException(item, key),
          };
        case 'number-unit':
          return {
            key: handleNullException(item, key),
            content: `${handleNumber(item, key)} ${handleNullException(item, dataSource[0])}`,
          };
        case 'status':
          return {
            key: handleNullException(item, dataSource[0]),
            content: (
              <StatusLozenge
                statusText={handleNullException(item, dataSource[0])}
                categoryCode={handleNullException(item, dataSource[1])}
              />
            ),
          };
        case 'date':
          return {
            key: handleNullException(item, key),
            content: formatDate(handleNullException(item, key), 'DD MMM YYYY'),
          };
        case 'dispatchNote-text':
          return {
            key: handleNullException(latestDispatchNote, key),
            content: handleNullException(latestDispatchNote, key),
          };
        case 'dispatchNote-date':
          return {
            key: handleNullException(latestDispatchNote, key),
            content: formatDate(handleNullException(latestDispatchNote, key), 'DD MMM YYYY'),
          };
        case 'alternative-text':
          return {
            key: handleNullException(item, key) || handleNullException(item, dataSource[0]),
            content: handleNullException(item, key) || handleNullException(item, dataSource[0]),
          };
        case 'related-elements':
          return {
            content: latestDispatchNote ? (
              <div style={{ margin: 'auto', width: '3rem' }}>
                <FetchRelatedElements
                  key={item.id}
                  fetchFn={() =>
                    JobService.getAttachmentList({
                      filter: `entityName:${ATTACHMENT_ENTITY_NAME},entityId:${latestDispatchNote?.id}`,
                      sort: 'updatedAt:DESC',
                    })
                  }
                  renderType={'attachment-popup'}
                  dataSource={['id', 'asset.fileName', 'asset.url']}
                  isPaginated={false}
                />
              </div>
            ) : null,
          };
        default:
          return { content: '' };
      }
    });
    return { cells };
  });
};

export const getReportArrSegments = (data: iJob[], selectedColumns: iConfigColumn[]) => {
  const selectedColumnNames = selectedColumns
    .map((column: iConfigColumn) => column.name)
    .filter((columnName: string) => columnName !== '');

  const reportCells = data.map((item: iJob) => {
    const latestDispatchNote: iDispatchNote | undefined =
      item.dispatchNoteItems.length > 0 ? _.sortBy(item.dispatchNoteItems, 'updatedAt')[0].dispatchNote : undefined;

    return selectedColumns.map((column: iConfigColumn) => {
      const { key, dataSource, type } = column;
      switch (type) {
        case 'text':
          return handleNullException(item, key);
        case 'number-unit':
          return `${handleNumber(item, key)} ${handleNullException(item, dataSource[0])}`;
        case 'date':
          return `${formatDate(handleNullException(item, key), 'DD MMM YYYY')}`;
        case 'status':
          return handleNullException(item, 'status.name');
        case 'dispatchNote-text':
          return handleNullException(latestDispatchNote, key);
        case 'dispatchNote-date':
          return formatDate(handleNullException(latestDispatchNote, key), 'DD MMM YYYY');
        case 'alternative-text':
          return handleNullException(item, key) || handleNullException(item, dataSource[0]);
        case 'history':
          return `${handleNullException(item, dataSource[0])} @ ${formatDate(
            handleNullException(item, key),
            'DD MMM YYYY',
          )}`;
        default:
          return '';
      }
    });
  });

  return [selectedColumnNames, ...reportCells];
};

export type iOrdersProps = {
  isLoading: boolean;
  data: Array<iJob>;
  columns: Array<iConfigColumn>;
  onSetSort: (sortStr: string) => void;
};

const Orders = ({ data, isLoading, columns, onSetSort }: iOrdersProps) => {
  const [sortOrder, setSortOrder] = useState<iSortOrderType>();
  const [sortKey, setSortKey] = useState<string>();

  return (
    <DynamicTable
      testId={'orders-table'}
      head={getHeads(columns, ORDER_PAGE_NAME)}
      rows={getRows({
        data,
        columns,
      })}
      isLoading={isLoading}
      onSort={
        //  eslint-disable-next-line
        (data: any) => {
          setSortKey(data.key);
          setSortOrder(data.sortOrder);
          onSetSort(`${data.key}:${data.sortOrder}`);
        }
      }
      sortKey={sortKey}
      sortOrder={sortOrder}
    />
  );
};

export default Orders;
