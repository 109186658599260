/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_URL_LOGIN } from './UrlMap';
import AuthService from '../services/AuthService';
import PageInit from './PageInit';
import { RootState } from '../redux/makeReduxStore';
import { userAuthenticated, removedAuthentication } from '../redux/reduxers/auth.slice';
import { removeModules, setModules } from '../redux/reduxers/companyModules.slice';
import { addToastForAPIResponse } from './toast/ToastHandler';
import ModuleEnabledWrapper from './modulize/ModuleEnabledWrapper';
import { ModuleCode } from '../types/iModuleCode';
import ModuleException from './modulize/ModuleException';

type iState = {
  loading?: boolean;
};
const initialState: iState = {
  loading: true,
};
const ProtectedRoute = ({ element }: { element: React.ReactElement }) => {
  const [state, setState] = useState(initialState);
  const { isAuthenticated, user } = useSelector((s: RootState) => s.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.id) {
      setState(s => ({ ...s, loading: false }));
      return;
    }
    let isCancelled = false;
    const getCurrentUser = () => {
      setState(s => ({ ...s, loading: true }));
      AuthService.getMe()
        .then(res => {
          if (isCancelled === true) return;
          dispatch(userAuthenticated(res));
          dispatch(setModules({ modules: res.user.companyModules || [] }));
          setState(s => ({ ...s, loading: false }));
        })
        .catch(() => {
          if (isCancelled === true) return;
          addToastForAPIResponse('error', 'Unauthorized, plz login');
          setState(s => ({ ...s, loading: false }));
          AuthService.removeLocalAuthToken();
          dispatch(removedAuthentication());
          dispatch(removeModules());
        });
    };
    getCurrentUser();
    // eslint-disable-next-line
    return () => {
      isCancelled = true;
    };
  }, [dispatch, user]);

  if (state.loading === true) {
    return <PageInit />;
  }

  if (isAuthenticated !== true) {
    // const renderComponent = () => <Navigate to={{ pathname: AUTH_URL_LOGIN }} />;
    return <Navigate to={{ pathname: AUTH_URL_LOGIN }} />;
  }

  return (
    <ModuleEnabledWrapper checkedModule={ModuleCode.CUSTOMER_PORTAL} exception={<ModuleException />}>
      {element}
    </ModuleEnabledWrapper>
  );
};

export default ProtectedRoute;
