import React, { useState } from 'react';
import Popup from '@atlaskit/popup';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import { Profile } from '@atlaskit/atlassian-navigation';
import { ButtonItem, Section } from '@atlaskit/menu';
import LogoutBtn from '../auth/LogoutBtn';
import { USER_PROFILE_URL } from '../UrlMap';
import { RootState } from '../../redux/makeReduxStore';

type iState = {
  isOpen: boolean;
};
const initialState: iState = {
  isOpen: false,
};
const PopupWrapper = styled.div``;

const UserAvatarPopup = () => {
  const [state, setState] = useState(initialState);
  const { user } = useSelector((s: RootState) => s.auth);
  const navigate = useNavigate();

  const showPopup = (isOpen: boolean) => {
    setState({
      ...state,
      isOpen,
    });
  };
  // loading machines before redirect to user profile page
  const navToProfile = () => {
    navigate(USER_PROFILE_URL);
  };

  const getMenuItems = () => {
    return (
      <PopupWrapper data-testid={'nav-avatar'}>
        <Section>
          <ButtonItem onClick={navToProfile} testId={'nav-profile'}>
            My profile
          </ButtonItem>
        </Section>
        <Section hasSeparator>
          <LogoutBtn>
            <ButtonItem testId={'nav-logout'}>Logout</ButtonItem>
          </LogoutBtn>
        </Section>
      </PopupWrapper>
    );
  };
  const userProfileIcon = () => {
    return user?.avatarUrl ? (
      <img
        style={{ borderRadius: '50%', width: 24, height: 24 }}
        src={user.avatarUrl}
        alt={user.avatarUrl}
        data-testid={'user-avatar-img'}
      />
    ) : (
      <UserAvatarCircleIcon label={''} testId={'default-user-avatar'} />
    );
  };
  return (
    <Popup
      testId={'avatar-change-popup'}
      isOpen={state.isOpen}
      placement={'bottom-end'}
      content={getMenuItems}
      onClose={() => showPopup(false)}
      trigger={(
        triggerProps: any, //eslint-disable-line
      ) => (
        <Profile
          {...triggerProps} //eslint-disable-line
          icon={userProfileIcon()}
          tooltip={''}
          onClick={() => showPopup(!state.isOpen)}
          testId={'nav-profile-trigger'}
        />
      )}
    />
  );
};
export default UserAvatarPopup;
