import { iKeyValuePairs, iParams } from '../shared/UITypes/ui.types';
import AppService from './AppService';

const getJobs = (config?: iKeyValuePairs) => {
  return AppService.get('/jobs', {
    ...config,
    include: 'machine,product.measurement,status.jobStatusCategory,dispatchNoteItems.dispatchNote.courier',
  }).then(({ data }) => data);
};

const getJobCategories = () => {
  return AppService.get('/jobStatusCategory', {
    include: 'jobStatuses',
  }).then(({ data }) => data);
};

const getAttachmentList = (config?: iParams) => {
  return AppService.get('/attachment', { ...config, include: 'asset' }).then(({ data }) => data);
};

export default {
  getJobs,
  getJobCategories,
  getAttachmentList,
};
