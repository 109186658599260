import React from 'react';
import {
  AtlassianNavigation,
  CustomProductHome,
  PrimaryButton,
  PrimaryButtonProps,
  useOverflowStatus,
} from '@atlaskit/atlassian-navigation';
import { ButtonItem } from '@atlaskit/menu';
import { useNavigate } from 'react-router-dom';
import { BACK_END_URL } from '../../shared/UrlMap';
import UserAvatarPopup from '../../shared/user/UserAvatarPopup';
import OrderMenu from '../../shared/topNavPopups/OrderMenu';
// import { RootState } from '../../redux/makeReduxStore';

const ResponsiveBtn = (props: PrimaryButtonProps) => {
  const overflowStatus = useOverflowStatus();
  return overflowStatus.isVisible ? (
    <PrimaryButton {...props} /> // eslint-disable-line
  ) : (
    <ButtonItem>{props.children}</ButtonItem> // eslint-disable-line
  );
};

const customIcon = '';
const TopNav = () => {
  // const { user } = useSelector((s: RootState) => s.auth);
  const navigate = useNavigate();

  const navToHome = () => {
    navigate(BACK_END_URL);
  };

  const getCustomLogo = () => {
    return process.env.REACT_APP_COMPANY_LOGO || '';
  };

  const logoBtn = () => (
    <CustomProductHome
      // href={BACK_END_URL}
      onClick={navToHome}
      iconAlt="Atlassian Documentation"
      iconUrl={customIcon}
      logoAlt="Atlassian Documentation"
      logoUrl={getCustomLogo()}
      logoMaxWidth={300}
      testId={'nav-logo'}
    />
  );

  const UserProfile = () => {
    return <UserAvatarPopup />;
  };

  return (
    <AtlassianNavigation
      label="site"
      primaryItems={[
        <ResponsiveBtn
          // href={BACK_END_URL}
          onClick={navToHome}
          testId={'nav-home'}
          key={'nav-home'}
        >
          Home
        </ResponsiveBtn>,
        <OrderMenu key={'order-menu'} />,
      ]}
      renderProductHome={logoBtn}
      // renderAppSwitcher={SwitcherPopup}
      // renderCreate={DefaultCreate}
      // renderHelp={HelpPopup}
      // renderNotifications={Notifications}
      renderProfile={UserProfile}
      // renderSearch={DefaultSearch}
      // renderSettings={DefaultSettings}
    />
  );
};

export default TopNav;
