export const AUTH_URL_LOGIN = '/login';
export const BACK_END_URL = '/b/';
export const USER_PROFILE_URL = '/b/profile';
export const CUSTOMER_URL = '/b/customer';
export const SUPPLIER_URL = '/b/supplier';
export const CUSTOMER_DETAIL_URL = '/b/customer/:id';
export const SUPPLIER_DETAIL_URL = '/b/supplier/:id';
export const JOB_URL = '/b/job';
export const JOB_DETAILS_URL = '/b/job/:id';
export const JOB_SCHEDULER_URL = '/b/job/scheduler';
export const SALES_URL = '/b/sales';
export const SALES_ORDER_DETAIL_URL = '/b/sales/:id';
export const PURCHASES_URL = '/b/purchases';
export const PURCHASE_ORDER_DETAIL_URL = '/b/purchases/:id';
export const PURCHASE_ORDER_RECEIVING_URL = '/b/purchases/receiving/:id';
export const PRODUCTS_URL = '/b/products';
export const PRODUCT_DETAIL_URL = '/b/product/:id';
export const PRODUCT_TYPES_URL = '/b/productTypes';
export const PRODUCT_ATTRIBUTE_SETS_URL = '/b/productAttributeSets';
export const WAREHOUSE_URL = '/b/warehouse';
export const WAREHOUSE_DETAIL_URL = '/b/warehouse/:id';
export const ITEM_LOCATIONS_URL = '/b/itemLocations';
export const SETTINGS_URL = '/b/settings/:slug';
export const SETTINGS_MACHINES_URL = '/b/settings/machines';
export const SETTINGS_USERS_URL = '/b/settings/users';
export const SETTINGS_COMPANY_URL = '/b/settings/company';
export const SETTINGS_TAX_URL = '/b/settings/tax';
export const SETTINGS_UNIT_URL = '/b/settings/unit';
export const SETTINGS_SHIFTS_URL = '/b/settings/shifts';
export const SETTINGS_COURIERS_URL = '/b/settings/couriers';
export const DISPATCH_NOTES_URL = '/b/dispatchNotes';
export const DISPATCH_NOTE_DETAIL_URL = '/b/dispatchNotes/:id';
export const STOCK_TRANSFER_URL = '/b/stockTransfer';
export const STOCK_TAKE_URL = '/b/stockTake';
export const STOCK_TAKE_DETAIL_URL = '/b/stockTake/:id';
export const NCR_DETAIL_URL = '/b/NCR/:id';
export const NCRS_URL = '/b/NCR';
export const JOBS_CURRENT_URL = '/b/jobs/current';
export const JOBS_FUTURE_URL = '/b/jobs/future';
export const JOB_CONTINUE_URL = '/b/job/continue/:id';
export const JOB_DISPATCH_URL = '/b/job/dispatch';
export const FINISHING_SHIFT_URL = '/b/finishShift';
