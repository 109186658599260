import { iConfigColumn } from '../../../shared/UITypes/ui.types';

export const ORDER_PAGE_NAME = 'Orders';

export const ORDER_COLUMNS: Array<iConfigColumn> = [
  {
    name: 'Job #',
    key: 'jobNumber',
    dataSource: ['id'],
    isCompulsory: true,
    type: 'text',
    isSelected: true,
    isSortable: true,
    group: '',
  },
  {
    name: 'Status',
    key: 'statusId',
    dataSource: ['status.name', 'status.jobStatusCategory.name'],
    type: 'status',
    isSelected: true,
    isSortable: true,
    group: '',
  },
  {
    name: 'Ref No.',
    key: 'customerOrderRef',
    dataSource: ['salesOrder.customerRef'],
    type: 'alternative-text',
    isSelected: true,
    group: '',
    isSortable: true,
  },
  {
    name: 'Product Code',
    key: 'product.productCode',
    dataSource: ['product.productCode'],
    type: 'text',
    isSelected: true,
    isSortable: false,
    group: '',
  },
  {
    name: 'Product Name',
    key: 'product.name',
    dataSource: ['product.name'],
    type: 'text',
    isSelected: true,
    isSortable: false,
    group: '',
  },
  {
    name: 'Quantity',
    key: 'qtyTotal',
    dataSource: ['product.measurement.shortName'],
    type: 'number-unit',
    isSelected: true,
    isSortable: false,
    group: '',
  },
  {
    name: 'Scheduled At',
    key: 'scheduledAt',
    dataSource: ['scheduledAt'],
    type: 'date',
    isSelected: true,
    isSortable: true,
    group: '',
  },
  {
    name: 'Dispatched Date',
    key: 'dispatchDate',
    dataSource: ['dispatchedate'],
    type: 'dispatchNote-date',
    isSelected: true,
    group: '',
  },
  {
    name: 'Courier',
    key: 'courier.name',
    dataSource: ['courier.name'],
    type: 'dispatchNote-text',
    isSelected: true,
    group: '',
  },
  {
    name: 'CourierRef',
    key: 'courierReference',
    dataSource: ['courierRef'],
    type: 'dispatchNote-text',
    isSelected: false,
    isSortable: false,
    group: '',
  },
  {
    name: 'Attachments',
    key: 'attachments',
    dataSource: [''],
    type: 'related-elements',
    isSelected: true,
    group: '',
    isSortable: false,
  },
];

export const COMPANY_EMAIL = 'sales@miesoft.com.au';
export const COMPANY_NAME = 'MIESoft';
export const JOB_ALL = 'All';
export const ORDERS = 'Orders';
export const ATTACHMENT_ENTITY_NAME = 'DispatchNote';
