import AppService from './AppService';

const updateUserProfile = (name: string, newValue: string) => {
  return AppService.put('/profile', { [name]: newValue }).then(({ data }) => {
    return data;
  });
};

const precheckPasswordComplexity = (password: string) => {
  return AppService.get('/validatePassword', { password }).then(({ data }) => data);
};

export default {
  updateUserProfile,
  precheckPasswordComplexity,
};
