import React from 'react';
import Lozenge from '@atlaskit/lozenge';

import {
  STATUS_CATEGORY_NEW,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_FINISHED,
} from '../constants/CategoryConstant';

const selectColor = (categoryCode: string) => {
  switch (categoryCode) {
    case STATUS_CATEGORY_NEW:
      return 'default';
    case STATUS_CATEGORY_IN_PROGRESS:
      return 'inprogress';
    case STATUS_CATEGORY_FINISHED:
      return 'success';
    default:
      return 'default';
  }
};

const StatusLozenge = ({ statusText, categoryCode }: { statusText: string; categoryCode: string }) => (
  <Lozenge appearance={selectColor(categoryCode)}>{statusText}</Lozenge>
);

export default StatusLozenge;
