import React from 'react';
import CompanyExceptionEmptyState from './CompanyExceptionEmptyState';

// eslint-disable-next-line
const CompanyDomainUnknown = () => {
  const props = {
    header: 'Unfortunately, We can not find this url on our service list',
    description: 'Pls contact support to see the issue.',
    primaryAction: '',
  };
  // eslint-disable-next-line
  return <CompanyExceptionEmptyState {...props} />;
};

export default CompanyDomainUnknown;
