import React, { useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@atlaskit/button';
import { removedAuthentication } from '../../redux/reduxers/auth.slice';
import { AUTH_URL_LOGIN } from '../UrlMap';
import AuthService from '../../services/AuthService';

const LogoutBtnWrapper = styled.div``;
const initialState = {
  confirming: false,
  loggingOut: false,
};
const LogoutBtn = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClicked = () => {
    setState({
      ...state,
      confirming: true,
    });
  };

  const close = () => {
    setState({
      ...state,
      confirming: false,
    });
  };

  const doLogout = () => {
    setState({
      ...state,
      loggingOut: true,
    });
    AuthService.logout().then(() => {
      dispatch(removedAuthentication());
      navigate(AUTH_URL_LOGIN);
    });
  };

  const getModalContent = () => {
    if (state.confirming !== true) return null;
    return (
      <Modal testId="logout-popup" onClose={() => close()}>
        <ModalHeader>
          <ModalTitle>Are you sure to logout?</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Please confirm you are about to logout.</p>
        </ModalBody>
        <ModalFooter>
          <Button appearance="subtle" onClick={() => close()} testId={'cancel-btn'}>
            Cancel
          </Button>
          <Button appearance="primary" onClick={() => doLogout()} testId={'confirm-btn'} autoFocus>
            Logout
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const getBtn = () => {
    if (state.loggingOut !== true) {
      return <LogoutBtnWrapper onClick={handleClicked}>{children}</LogoutBtnWrapper>;
    }
    return <Spinner />;
  };

  return (
    <>
      {getBtn()}
      <ModalTransition>{getModalContent()}</ModalTransition>
    </>
  );
};

export default LogoutBtn;
