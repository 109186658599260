import _ from 'lodash';
import moment from 'moment';
import { iConfigColumn } from '../shared/UITypes/ui.types';
import iAddress from '../types/address/iAddress';

export const ucFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};
//  2345 to 2,345
export const numberFormat = (str: string | number) => {
  const convert = Number(str);
  return Number.isNaN(convert) ? str.toString() : new Intl.NumberFormat().format(convert);
};

//  default round to integer
export const numberRound = (num: string | number | null, decimal?: number) => {
  if (num === null) return null;
  const convert = Number(num);
  //  eslint-disable-next-line no-nested-ternary
  const result = Number.isNaN(convert) ? num : decimal !== undefined ? _.round(convert, decimal) : _.round(convert, 0);
  return result.toLocaleString();
};

export const numberToPercentage = (num: string | number, float?: number) => {
  const convert = Number(num);
  const roundDecimal = float || 2;
  return Number.isNaN(convert) ? num.toString() : `${parseFloat((convert * 100).toString()).toFixed(roundDecimal)}%`;
};

export const compareStr = (strA: string | null, strB: string | null) => {
  if (strA === null) {
    return 1;
  }
  if (strB === null) {
    return -1;
  }
  if (strA < strB) {
    return -1;
  }
  if (strA > strB) {
    return 1;
  }
  return 0;
};
export const formatDate = (date?: string | null, format?: string) => {
  if (!date) return '';
  const defaultFormat = format || 'DD MMMM YYYY, h a';
  //  moment.locale('en-au');
  return moment(date).format(defaultFormat);
};
export const calculateDurationToNow = (date?: string) => {
  if (!date) return '';
  return moment.duration(moment(date).diff(moment.now())).asHours();
};

export const addsOnValue = (value: string | number, prefix?: string | null, postfix?: string | null) => {
  let viewValue = value;
  if (prefix) {
    viewValue = `${prefix} ${viewValue}`;
  }
  if (postfix) {
    viewValue = `${viewValue} ${postfix}`;
  }
  return viewValue.toString();
};

export const currencyFormatter = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AUD',
  });
  return formatter.format(value);
};
export const mapToLabelValuePair = <T extends { id: string; name: string }>(list: Array<T>) => {
  if (list?.length === 0) return [];
  //    eslint-disable-next-line
  return list?.map((l: T) => ({ label: l.name, value: l.id }));
};

//  eslint-disable-next-line
export const hasKey = <O>(obj: O, key: keyof any): key is keyof O => {
  return key in obj;
};

export const assembleAddress = (address?: iAddress): string => {
  if (!address) return '';

  const street = !address.street ? '' : address.street;
  const suburb = !address.suburb ? '' : address.suburb;
  const state = !address.state ? '' : address.state;
  const postcode = !address.postcode ? '' : address.postcode;
  const country = !address.country ? '' : address.country;

  return street === '' && suburb === '' && state === '' && postcode === '' && country === ''
    ? ''
    : `${street}, ${suburb} ${state} ${postcode} ${country}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleNullException = (object: any, columnName: string) => {
  const getValue = _.get(object, columnName);
  if (_.isNull(getValue) || _.isUndefined(getValue)) return '';
  return getValue;
};

export const handleNumber = (
  //  eslint-disable-next-line
  object: any,
  columnName: string,
) => {
  const getValue = _.get(object, columnName);
  if (_.isNull(getValue) || _.isUndefined(getValue)) {
    return '';
  }
  const convertNumber = Number(getValue);
  if (Number.isNaN(convertNumber)) {
    return '';
  }
  return convertNumber.toLocaleString();
};

export const handleMoney = (
  //  eslint-disable-next-line
  object: any,
  columnName: string,
  decimal?: number,
  dollarSign?: boolean,
) => {
  //  by default, with dollar sign
  const sign = typeof dollarSign === 'undefined' ? true : dollarSign;
  const getValue = _.get(object, columnName);
  if (_.isNull(getValue) || _.isUndefined(getValue) || Number.isNaN(Number(getValue))) return '';

  const afterRound = decimal ? _.round(Number(getValue), decimal) : _.round(Number(getValue), 0);

  return sign ? `$${afterRound.toLocaleString()}` : afterRound.toLocaleString();
};

export const getHeads = (columns: Array<iConfigColumn>, tableName: string) => {
  const cells = columns.map((column: iConfigColumn) => {
    switch (column.type) {
      case 'operation':
      case 'delete':
        return {
          key: column.key,
          testId: `${tableName}-column-${column.key}`,
        };
      default:
        return {
          key: column.key,
          content: column.name,
          testId: `${tableName}-column-${column.key}`,
          isSortable: !!column.isSortable,
        };
    }
  });
  return { cells };
};

export const validateNumber = (value: string) => {
  const convertNumber = Number(value);
  if (Number.isNaN(convertNumber)) {
    return false;
  }
  return true;
};

export const getExportReportName = (prefix: string) => {
  return `${prefix}-report-${formatDate(moment().toISOString(), 'DD MMMM YYYY, hh:mm:ss')}.csv`;
};

/* intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > _([1,2,3]).intersperse(0)
 * [1,0,2,0,3]
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function intersperse(arr: any[], sep: string) {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (xs, x, i) => {
      return xs.concat([sep, x]);
    },
    [arr[0]],
  );
}
