import React from 'react';
import Textfield from '@atlaskit/textfield';
import styled from 'styled-components';
import { fontSize, gridSize, fontFamily } from '@atlaskit/theme/constants';
import { ErrorMsg } from '../styles/styles';

export type textfieldProps = {
  placeholder?: string;
  label?: React.ReactNode;
  helper?: React.ReactNode;
  name: string;
  onChange?: (newValue: string) => void;
  defaultValue?: string;
  type?: string;
  appStyle?: 'default' | 'danger' | 'success' | 'warning';
  // suffix?: string;
  // prefix?: string;
  validateErrorMessage?: string;
  onPrecheck?: (e: React.FocusEvent<HTMLInputElement>) => void;
};
const TextFieldWrapper = styled.div`
  &.danger {
    .helper {
      color: #de350b;
    }
  }
  &.validation-error > div {
    border-color: #bf1650;
  }
  .label {
    font-size: ${fontSize() / 17}rem;
    margin: ${gridSize() / 2}px 0;
    display: block;
    font-style: inherit;
    line-height: 1.3333333333333333;
    color: #6b778c;
    font-weight: 600;
    font-family: ${fontFamily()};
  }
  .helper {
    font-size: ${fontSize() / 18}rem;
    padding: ${gridSize() / 2}px;
  }
`;
const TextFieldAdaptor = ({
  defaultValue,
  placeholder,
  label,
  helper,
  name,
  type,
  appStyle,
  onChange,
  // suffix,
  // prefix,
  validateErrorMessage,
  onPrecheck,
}: textfieldProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return;
    }
    onChange(e.target.value);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onPrecheck !== 'function') return;
    onPrecheck(e);
  };
  // console.log(appStyle);
  return (
    <TextFieldWrapper className={`${validateErrorMessage && 'validation-error'} ${appStyle || 'default'}`}>
      {label ? (
        <label className={'label'} htmlFor={name}>
          {label}
        </label>
      ) : null}
      <Textfield
        name={name}
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        testId={name}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      {helper ? <div className={'helper'}>{helper}</div> : null}
      {validateErrorMessage ? <ErrorMsg>{validateErrorMessage}</ErrorMsg> : null}
    </TextFieldWrapper>
  );
};

export default TextFieldAdaptor;
