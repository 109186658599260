import React from 'react';

const OrderListPageHeader = ({
  welcomeMsg,
  jobFilter,
}: {
  welcomeMsg?: React.ReactNode;
  jobFilter: React.ReactNode;
}) => {
  return (
    <>
      {welcomeMsg}
      {jobFilter}
    </>
  );
};

export default OrderListPageHeader;
