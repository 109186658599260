import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import CameraIcon from '@atlaskit/icon/glyph/camera';
import { colors } from '@atlaskit/theme';
import { v4 as uuidv4 } from 'uuid';
import CenteredOverlay from '../imageUpload/CenteredOverlay';
import { iImage, onImagesSelected } from '../imageUpload/ImageHelper';

interface iStyleProps {
  avatarUrl: string;
  onClick: () => void;
}
const CircleWrapper = styled.div<iStyleProps>`
  & {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    border-radius: 50%;
    background-color: ${colors.subtleText};
    position: relative;
  }
  &:after {
    padding-bottom: 100%;
    background-color: ${colors.subtleText};
    content: '';
  }
  ${props =>
    props.avatarUrl !== '' &&
    `
    background-image: url(${props.avatarUrl});
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
  `}
`;
const InvisibleInput = styled.input`
  display: none;
`;
const imageUploadHint = 'click above picture to change your profile photo';

export type iUserAvatarUpload = {
  name: string;
  defaultValue?: string;
  onConfirm?: (name: string, newValue: string) => Promise<void>;
};
const UserAvatarUpload = ({ name, defaultValue, onConfirm }: iUserAvatarUpload) => {
  const initImages: iImage | undefined = defaultValue
    ? {
        id: uuidv4(),
        url: defaultValue,
        uploading: false,
      }
    : undefined;
  const [image, setImage] = useState(initImages);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const openFileBrowser = () => {
    return inputFileRef.current?.click();
  };
  // action taken after image uploaded
  const omImageUpload = async (img: iImage) => {
    const isNewImage = image ? image.id === img.id : true;
    const newImage = isNewImage ? img : { ...image, ...img };
    setImage(() => newImage);
    if (typeof onConfirm === 'function' && newImage.url && !newImage.uploading) {
      onConfirm(name, newImage.url);
    }
  };
  return (
    <>
      <CircleWrapper avatarUrl={image?.rawSrc || image?.url || ''} onClick={openFileBrowser} data-testid={'wrapper'}>
        {image ? (
          image?.uploading && (
            <CenteredOverlay style={{ borderRadius: '50%' }}>
              <p>Loading</p>
            </CenteredOverlay>
          )
        ) : (
          <div>
            <CameraIcon label={''} />
            <p>Upload image</p>
          </div>
        )}
      </CircleWrapper>
      <small>{image && imageUploadHint}</small>
      <InvisibleInput
        ref={inputFileRef}
        type="file"
        id="image-upload"
        data-testid={'image-upload'}
        onChange={() => inputFileRef.current && onImagesSelected(inputFileRef.current.files, omImageUpload)}
      />
    </>
  );
};
export default UserAvatarUpload;
