import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import iJob from '../../types/job/iJob';
import BackendPage from '../../layout/BackendPage';
import OrderTable, { getReportArrSegments } from './Orders';
import WelcomeMsg from '../../shared/user/WelcomeMsg';
import useListCrudHook from '../../shared/hooks/useListCrudHook/useListCrudHook';
import CustomizePagination from '../../shared/pagination/CustomizePagination';
import EmptyView from './EmptyView';
import { RootState } from '../../redux/makeReduxStore';
import JobService from '../../services/JobService';
import { JOB_ALL, ORDERS, ORDER_COLUMNS } from './shared/Order.constants';
import iJobCategory from '../../types/job/iJobCategory';
import {
  STATUS_CATEGORY_FINISHED,
  STATUS_CATEGORY_IN_PROGRESS,
  STATUS_CATEGORY_NEW,
} from '../../shared/constants/CategoryConstant';
import iJobStatus from '../../types/job/iJobStatus';
import { apiErrorToast } from '../../shared/toast/ToastHandler';
import OrderListPageHeader from './OrderListPageHeader';
import JobFilter from './filter/JobFilter';
import { FlexContainer } from '../../shared/styles/styles';
import SearchBar from '../../shared/search/SearchBar';
import useColumnHooksUpgrade from '../../shared/hooks/useShowHideColumnHook/useColumnHook';
import ExportReport from '../../shared/exportReport/ExportReport';
import PaginationDetail from '../../shared/pagination/paginationDetail/PaginationDetail';
import { getExportReportName } from '../../services/UtilsService';
import Spinner from '@atlaskit/spinner';

type iOrderListState = {
  selectedTypes: Array<string>;
  statuses: Array<iJobStatus>;
  keyword: string;
  isLoading: boolean;
  isAdvancedSearch: boolean;
  categories: Array<iJobCategory>;
  normalFilter: string;
};
const initialState = {
  selectedTypes: [],
  keyword: '',
  statuses: [],
  isLoading: true,
  isAdvancedSearch: false,
  categories: [],
  normalFilter: '',
};

const sortCategories = (categories: Array<iJobCategory>) => {
  const WHOLE = [STATUS_CATEGORY_NEW, STATUS_CATEGORY_IN_PROGRESS, STATUS_CATEGORY_FINISHED];
  return categories.sort((a: iJobCategory, b: iJobCategory) => WHOLE.indexOf(a.name) - WHOLE.indexOf(b.name));
};

const OrderList = () => {
  const [orderListState, setOrderListState] = useState<iOrderListState>(initialState);
  const { user } = useSelector((s: RootState) => s.auth);
  const { state, edit, onSetSort, onSetCurrentPage, onRefreshListToFirstPage, onFilter, onSearch } =
    useListCrudHook<iJob>({
      getFn: JobService.getJobs,
      keywordColumns: 'jobNumber',
      sort: 'updatedAt:DESC',
      paginationApplied: true,
      notRenderWithoutFilter: true,
    });
  const welComeDes = `Here is list of active orders under your company: ${user?.contactCompany?.name}`;

  const { columns, getShowHideColumns } = useColumnHooksUpgrade('customerOrder', ORDER_COLUMNS);

  useEffect(() => {
    let isCancelled = false;
    const loadJobCategories = async () => {
      setOrderListState(preState => ({ ...preState, isLoading: true }));
      try {
        const categories = await JobService.getJobCategories();

        const sortedCategories = sortCategories(categories);
        if (isCancelled) return;
        let defaultSelected: Array<string> = [];
        const statuses: Array<iJobStatus> = sortedCategories.reduce((acc: Array<iJobStatus>, cur: iJobCategory) => {
          if (cur.name !== STATUS_CATEGORY_FINISHED) {
            defaultSelected = [...defaultSelected, ...cur.jobStatuses.map((s: iJobStatus) => s.id)];
          }
          return [...acc, ...cur.jobStatuses.sort((a: iJobStatus, b: iJobStatus) => a.sortOrder - b.sortOrder)];
        }, []);
        setOrderListState(preState => ({
          ...preState,
          isLoading: false,
          statuses,
          selectedTypes: defaultSelected,
          categories,
          normalFilter: `statusId:${defaultSelected.join('|')}`,
        }));
        // onFilter(orderListState.normalFilter);
      } catch (error) {
        apiErrorToast(error);
        setOrderListState(preState => ({ ...preState, isLoading: false }));
      }
    };
    loadJobCategories();
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (!orderListState.isLoading) onFilter(orderListState.normalFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderListState.normalFilter]);

  const onSelectType = (value: string) => {
    // when nothing is selected, auto select 'JOB_ALL'
    if (orderListState.selectedTypes.length === 1 && orderListState.selectedTypes.includes(value)) {
      setOrderListState({
        ...orderListState,
        selectedTypes: [JOB_ALL],
        normalFilter: '',
      });
      return;
    }
    const newSelectedTypes = orderListState.selectedTypes.includes(value)
      ? orderListState.selectedTypes.filter((type: string) => type !== value)
      : [...orderListState.selectedTypes, value];
    setOrderListState({
      ...orderListState,
      selectedTypes: [...newSelectedTypes],
      normalFilter: newSelectedTypes.includes(JOB_ALL) ? '' : `statusId:${newSelectedTypes.join('|')}`,
    });
  };

  const getContent = () => {
    if (state.isLoading === true || orderListState.isLoading === true) {
      return <Spinner testId={'loading'} />;
    }
    return (
      <>
        <FlexContainer className={'space-between space-below'}>
          {!!state.total && !!state.from && !!state.to ? (
            <PaginationDetail
              from={state.from}
              to={state.to}
              total={state.total}
              onRefreshResults={onRefreshListToFirstPage}
            />
          ) : (
            <div />
          )}
          <div
            style={{
              display: 'flex',
              flexGrow: 0.03,
              justifyContent: 'space-between',
            }}
          >
            {!!state.total && (
              <ExportReport
                total={state.total}
                perPage={state.perPage}
                getFn={(currentPage: number) =>
                  JobService.getJobs({
                    currentPage: currentPage.toString(),
                    perPage: state.perPage?.toString() || '10',
                    sort: 'createdAt:DESC',
                    like: edit.keyword ? `jobNumber:${edit.keyword}` : '',
                    filter: orderListState.normalFilter || '',
                  })
                }
                onFormatReport={companys => getReportArrSegments(companys, columns)}
                reportFileName={getExportReportName(ORDERS)}
              />
            )}
            {getShowHideColumns()}
          </div>
        </FlexContainer>
        {state.data.length === 0 ? (
          <EmptyView companyName={user?.contactCompany?.name} />
        ) : (
          <OrderTable data={state.data} columns={columns} isLoading={state.isLoading} onSetSort={onSetSort} />
        )}
        {!!state.total && !!state.perPage && !!state.currentPage && (
          <CustomizePagination
            range={Math.ceil(state.total / state.perPage)}
            currentPage={state.currentPage}
            onChange={onSetCurrentPage}
          />
        )}
      </>
    );
  };

  return (
    <BackendPage
      pageHeader={
        <OrderListPageHeader
          welcomeMsg={
            <WelcomeMsg>
              <small>{welComeDes}</small>
            </WelcomeMsg>
          }
          jobFilter={
            <div style={{ marginTop: '10px' }}>
              <JobFilter
                options={[
                  { label: JOB_ALL, value: JOB_ALL },
                  ...orderListState.statuses.map((s: iJobStatus) => ({
                    label: s.name.toUpperCase(),
                    value: s.id,
                  })),
                ]}
                onSelectJobType={onSelectType}
                selectedTypes={orderListState.selectedTypes}
              >
                <SearchBar onSearch={onSearch} keyword={edit.keyword} placeholder={'Search Job #'} />
              </JobFilter>
            </div>
          }
        />
      }
    >
      {getContent()}
    </BackendPage>
  );
};

export default OrderList;
