import AppService from './AppService';
import LocalStorageService from './LocalStorageService';

const getLocalAuthToken = () => {
  const token = LocalStorageService.getToken();
  return typeof token !== 'string' ? '' : token;
};

const removeLocalAuthToken = () => {
  LocalStorageService.removeToken();
};

const login = (email: string, password: string) => {
  return AppService.post('/auth', { email, password }).then(({ data }) => {
    LocalStorageService.setToken(data.token);
    LocalStorageService.setCompanyId(data.user.companyId);
    return { user: data.user, isAuthenticated: true };
  });
};

const logout = () => {
  // return AppService.delete('/logout');
  return AppService.delete('/').then(res => {
    removeLocalAuthToken();
    LocalStorageService.removeCompanyId();
    return res;
  });
};
const getMe = () => {
  return AppService.get('/getMe', { include: 'company' }).then(({ data }) => {
    return {
      user: data.user,
    };
  });
};

export default {
  getLocalAuthToken,
  removeLocalAuthToken,
  login,
  logout,
  getMe,
};
