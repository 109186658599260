import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../redux/makeReduxStore';
import { ucFirst } from '../../services/UtilsService';

const WelcomeMsg = ({ children }: { children?: React.ReactNode }) => {
  const { user } = useSelector((s: RootState) => s.auth);

  const greetingText = () => {
    const now = moment();
    const currentHour = now.hour();
    if (currentHour < 12) {
      return 'Morning';
    }
    if (currentHour <= 17) {
      return 'Afternoon';
    }
    return 'Evening';
  };

  return (
    <div className={'welcome-msg-wrapper'}>
      <h3>
        {greetingText()}, {ucFirst(user?.name || '')}
      </h3>
      {children}
    </div>
  );
};
export default WelcomeMsg;
