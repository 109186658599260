import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Pagination from '@atlaskit/pagination';

const PaginationWrapper = styled.div`
  &.pagination-wrapper {
    margin: 6px auto;
    width: fit-content;
  }
`;
const CustomizePagination = ({
  range,
  onChange,
  currentPage,
  max = 7,
}: {
  range: number;
  onChange: (page: number) => void;
  currentPage: number;
  max?: number;
}) => {
  //    eslint-disable-next-line
  const handleChange = (e: any, page: any) => {
    onChange(page as number);
  };
  return range > 1 ? (
    <PaginationWrapper className={'pagination-wrapper'} data-testid="pagination-wrapper">
      <Pagination
        pages={[..._.range(1, range + 1)]}
        onChange={handleChange}
        // index = page - 1
        selectedIndex={currentPage - 1}
        max={max}
      />
    </PaginationWrapper>
  ) : null;
};

export default CustomizePagination;
