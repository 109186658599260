import styled from 'styled-components';

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &.space-below {
    padding-bottom: 1rem;
  }

  &.space-above {
    padding-top: 1rem;
  }
`;

export const ErrorMsg = styled.p`
  margin-top: 6px;
  color: #bf1650;
  &:before {
    content: '⚠ ';
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &.space-below {
    padding-bottom: 1rem;
  }
  &.space-evenly {
    justify-content: space-evenly;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.space-above {
    padding-top: 1rem;
  }
`;

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  &.space-evenly {
    justify-content: space-evenly;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.flex-start {
    justify-content: flex-start;
  }
  &.horizontal-center {
    align-items: center;
  }
`;

export const Page = styled.div`
  padding: 1rem;
  position: relative;
`;

export const PageTitle = styled.p`
  font-size: 1.75rem;
  font-weight: 500;
  color: #172b4d;

  &.small {
    font-size: 1.1rem;
  }

  &.median {
    font-size: 1.25rem;
  }

  &.space-right {
    margin-right: 0.5rem;
  }

  &.space-below {
    margin-bottom: 0.5rem;
  }

  &.space-above {
    margin-top: 0.5rem;
  }
`;

export const PageSubtitle = styled.p`
  font-size: 1rem;
  color: #6b778c;
  font-weight: 400;
`;

export const SpaceVerticalWrapper = styled.div`
  &.small {
    padding: 0.5rem 0;
  }
  &.median {
    padding: 1rem 0;
  }
  &.large {
    padding: 1.5rem 0;
  }
  &.x-large {
    padding: 5rem 0;
  }
`;

export const statusSelectorStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  minWidth: '70px',
  height: '25px',
  fontSize: '12px',
};

export const SpaceHorizontalContainer = styled.div`
  padding: 0 1.5rem;
  &.quarter {
    padding: 0 0.25rem;
  }
  &.half {
    padding: 0 0.5rem;
  }
  &.one {
    padding: 0 1rem;
  }
  &.half {
    padding: 0 0.5rem;
  }
  &.two {
    padding: 0 2rem;
  }
  &.three {
    padding: 0 3rem;
  }
  &.five {
    padding: 0 5rem;
  }
  &.seven {
    padding: 0 7rem;
  }
  &.eight {
    padding: 0 8rem;
  }
  &.ten {
    padding: 0 10rem;
  }
`;

export const ProgressBarContainer = styled.div`
  boxsizng: border-box;
  padding: 20;
  width: 600;
  border: none;
`;

export const BtnTransparentWrapper = styled.div`
  display: flex;
  .transparent-button {
    background-color: transparent;
  }
`;
