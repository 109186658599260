import React from 'react';

import ErrorIcon from '@atlaskit/icon/glyph/error';
import { Y200 } from '@atlaskit/theme/colors';

import Flag from '@atlaskit/flag';

const WarningFlag = ({ title }: { title: string }) => (
  <Flag
    appearance="warning"
    icon={<ErrorIcon label="Warning" secondaryColor={Y200} />}
    id="warning"
    key="warning"
    title={title}
  />
);

export default WarningFlag;
